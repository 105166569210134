import "../STCreate/PureComponents/STCreate.css";

import React, { useEffect, useRef, useState } from "react";
import {
  addingMultipleFilesST,
  createInternalServiceTicket,
} from "Config/API/action";
import {
  fileExplorerSearch,
  getAssignTech,
  getCategoryData,
  getLocationData,
  getTicketId,
} from "Config/API/get";
import { useLocation, useNavigate } from "react-router-dom";

import ExternalIcon from "Media/icons/External_icon.svg";
import Headers from "Components/Header/Headers";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import { Link } from "react-router-dom";
import Navigation from "Components/SideNavBar/Navigation";
import StorageLimitReachedPopup from "Components/Popup/StorageLimitReachedPopup/StorageLimitReachedPopup";
import excelIcon from "Media/icons/simple-microsoftexcel.svg";
import folderIcon from "Media/icons/Folder-Close.png";
import imageIcon from "Media/icons/simple-image.png";
import officeIcon from "Media/icons/simple-microsoftword.svg";
import pdfIcon from "Media/icons/simple-pdf.svg";
import { toast } from "react-toastify";
import unknownFile from "Media/icons/unknownFile.png";
import useFullPageLoader from "Hooks/useFullPageLoader";
import useIsStorageLimitReached from "Hooks/useIsStorageLimitReached";
import { useSelector } from "react-redux";
import videoFile from "Media/icons/videoFile.png";

const CreateInternalTicket = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const isStorageLimitReached = useIsStorageLimitReached();
  // org id for admin or employee
  let org_id = useSelector((state) => state.renderGeneralInfo?.org_id);
  let user_id = useSelector((state) => state.renderGeneralInfo?.id);
  const orgName = useSelector(
    (state) => state.renderGeneralInfo?.organization_name
  );
  const general_info = useSelector((state) => state.renderGeneralInfo);
  const previousUserData = useSelector(
    (state) => state.raiseRequestDataReducer
  );
  if (org_id?.toString() === "0") {
    org_id = user_id;
  }
  const token = localStorage.getItem("token");
  const [loader, setLoader, resetLoader] = useFullPageLoader();

  // folder listing
  const [folderList, setFolderList] = useState([]);
  const [folder, setFolder] = useState("");

  // input fields
  const [ticketId, setTicketId] = useState("");
  const [severity, setSeverity] = useState("none");
  const [machineNo, setMachineNo] = useState("");
  const [folderLink, setFolderLink] = useState("");
  const [folderId, setFolderId] = useState("");
  const [desc, setDesc] = useState("");
  const [files, setFiles] = useState([]);
  const [location_list, setLocation_list] = useState([]);
  const [location, setLocation] = useState("");
  const [teams, setTeams] = useState([]);
  const [category_id, setCategory_id] = useState("");
  const [assignedTech, setAssignedTech] = useState([]);
  const [assign_tech, setAssign_tech] = useState("");
  const [raise_request_id, setRaise_request_id] = useState(
    previousUserData[2] ? previousUserData[2] : ""
  );
  // to show popups
  const [showStorageLimitReachedPopup, setShowStorageLimitReachedPopup] =
    useState(false);
  const [ticketData, setTicketData] = useState([]);
  // firstly getting ticket id
  useEffect(() => {
    const ticket_id_status = getTicketId(token);
    ticket_id_status.then((res) => {
      if (res?.status) {
        setTicketId(res?.ticketId);
      }
    });
    if (state === "new ticket" || state?.type === "new ticket") {
      setTicketData([]);
    } else {
      setTicketData(previousUserData[6]);
      setFolder(previousUserData[6]?.connect_folder_name);
      setFolderLink(previousUserData[6]?.connect_folder);
      setFolderId(previousUserData[6]?.connect_folder_id);
    }
    getAllLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const categoryList = getCategoryData(token, general_info?.id, location);
    categoryList.then((res) => {
      setTeams(res?.category);
    });
  }, [location]);

  useEffect(() => {
    const userList = getAssignTech(
      token,
      location || ticketData?.location,
      category_id || ticketData?.category_id
    );
    userList.then((res) => {
      setAssignedTech(res?.data);
    });
  }, [category_id, ticketData?.category_id]);

  // testing ref for outside click      **IMPORTANT**

  const folderRef = useRef(null);

  useOutsideAlerter(folderRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          ref.current.classList.add("hide-dropdown");
        } else {
          if (ref.current !== null) {
            ref.current.classList.remove("hide-dropdown");
          }
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
const type=""
  const handleSubmit = (e) => {
    e.preventDefault();
    if (ticketId === "") return;
    if (severity === "none")
      return toast("Please select severity of ticket", { type: "info" });

    setLoader();
    var folder_name = folderId;

    if (folderId?.toString() === "" && folderLink?.toString() === "") {
      folder_name = folder;
    }
    const internal_ticket_status = createInternalServiceTicket(
      token,
      ticketId,
      severity,
      machineNo,
      folderLink,
      folder_name,
      ticketData?.files ? ticketData?.files : files,
      desc,
      ticketData?.location ? ticketData?.location : location,
      ticketData?.category_id ? ticketData?.category_id : category_id,
      assign_tech,
      raise_request_id,
      type
    );

    internal_ticket_status?.then((res) => {
      resetLoader();
      if (res?.status) {
        if (res?.message === "Ticket already created")
          return toast(res?.message, {
            type: "info",
          });
        toast(res?.message, {
          type: "success",
        });
        navigate("/all-internal-service-tickets");
      } else {
        toast(res?.message, {
          type: "error",
        });
      }
    });
  };

  const getAllLocations = () => {
    const locationList = getLocationData(token, general_info?.id);
    locationList.then((res) => {
      if (res?.location?.length > 0) {
        setLocation_list(res?.location);
      }
    });
  };
console.log(ticketData)
  return (
    <>
      <div className="stcreate-parent">
        <div className="stcreate-header">
          <Headers />
        </div>

        <div className="stcreate-navigation">
          <Navigation />
        </div>

        <div className="stcreate-main">
          <form
            className="stcreate-form-body"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="stcreate-heading">
              <p>Create A New Internal Ticket</p>
            </div>
            <div className="stcreate-wrapper">
              <div className="stcreate-fields">
                <label htmlFor="ticket-no">
                  Ticket # (Auto Generate)<span>*</span>:
                </label>
                <input
                  type="text"
                  id="ticket-no"
                  value={ticketId}
                  required
                  readOnly
                />
              </div>

              <div className="stcreate-fields">
                <label htmlFor="organization">
                  Organization<span>*</span>:
                </label>

                <input
                  type="text"
                  id="organization"
                  value={orgName}
                  required
                  readOnly
                />
              </div>

              <div className="stcreate-fields">
                <label htmlFor="severity">
                  Select Severity Of Issue<span>*</span>:
                </label>

                <select
                  required
                  id="severity"
                  className="severity-wrapper"
                  defaultValue={severity}
                  onChange={(e) => {
                    setSeverity(e.target.value);
                  }}
                >
                  <option className="severity-row" value="none" disabled>
                    -- Select an option --
                  </option>
                  <option className="severity-row" value="urgent">
                    Urgent
                  </option>
                  <option className="severity-row" value="moderate">
                    Moderate
                  </option>
                  <option className="severity-row" value="low">
                    Low
                  </option>
                </select>
              </div>
              <div className="stcreate-fields">
                <label htmlFor="folder">Select Folder (Internal):</label>
                <div ref={folderRef}>
                  <input
                    type="search"
                    id="folder"
                    readOnly={ticketData?.connect_folder_name?.length > 0}
                    style={{
                      backgroundColor:
                        ticketData?.connect_folder_name?.length > 0 &&
                        "#f1f2f6",
                    }}
                    value={folder}
                    onClick={(e) => {
                      if (ticketData?.connect_folder_name?.length > 0) return;
                      const folder_status = fileExplorerSearch(
                        token,
                        "",
                        org_id
                      );
                      folder_status.then((res) => {
                        if (res?.status) {
                          setFolderList(res?.result);
                        } else {
                          toast(res?.message, {
                            type: "error",
                          });
                        }
                      });
                    }}
                    onChange={(e) => {
                      setFolder(e.target.value);
                      const folder_status = fileExplorerSearch(
                        token,
                        e.target.value,
                        org_id
                      );
                      folder_status.then((res) => {
                        if (res?.status) {
                          setFolderList(res?.result);
                        } else {
                          toast(res?.message, {
                            type: "error",
                          });
                        }
                      });
                    }}
                  />

                  {/****************  Folder List ***************/}
                  <div className="organization-list folder-list">
                    {folderList?.length?.toString() !== "0" ? (
                      !folderList?.message ? (
                        // eslint-disable-next-line array-callback-return
                        folderList?.map((curr, index) => {
                          if (curr?.type?.toString() === "folder") {
                            return (
                              <div
                                className="data-list"
                                onClick={() => {
                                  setFolderLink(curr?.sharePath);
                                  setFolderId(curr?.unique_id);
                                  setFolder(curr?.name);
                                  setFolderList([]);
                                }}
                                key={index}
                              >
                                <div>
                                  <div className="organization-img-parent">
                                    <img src={folderIcon} alt="Folder" />
                                    {curr?.scope === "external" ? (
                                      <img
                                        src={ExternalIcon}
                                        alt="External"
                                        style={{
                                          position: "absolute",
                                          right: "0",
                                          bottom: "-10px",
                                          width: "17.5px",
                                        }}
                                      />
                                    ) : null}
                                  </div>

                                  <span className="multiple-line-text">
                                    <span>
                                      {curr?.name ? curr?.name : "No Name"}
                                    </span>
                                    <i>
                                      ( {curr?.bookmark ? curr?.bookmark : null}{" "}
                                      )
                                    </i>
                                  </span>
                                </div>
                              </div>
                            );
                          }
                        })
                      ) : (
                        <div className="orgListAlert">No Such Folder Found</div>
                      )
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="stcreate-fields">
                <label htmlFor="machine-no">Machine Serial #:</label>
                <input
                  type="text"
                  id="machine-no"
                  onChange={(e) => {
                    setMachineNo(e.target.value);
                  }}
                />
              </div>

              <div className="stcreate-fields stcreate-file-upload">
                <label htmlFor="file">Upload Files:</label>
                <div className="files-wrappper">
                  <div style={{ width: "100%", marginTop: "10px" }}>
                    {ticketData?.files?.length !== 0 ? (
                      ticketData?.files?.map((curr, inx) => {
                        const fileType = curr?.aws_name?.split(".").at(-1);
                        let icon = "";

                        switch (fileType) {
                          case "png":
                          case "jpg":
                          case "jpeg":
                            icon = imageIcon;
                            break;

                          case "pdf":
                            icon = pdfIcon;
                            break;

                          case "docx":
                          case "doc":
                            icon = officeIcon;
                            break;

                          case "xlsx":
                          case "xls":
                          case "csv":
                            icon = excelIcon;
                            break;

                          case "mp4":
                          case "mkv":
                          case "webm":
                            icon = videoFile;
                            break;

                          default:
                            icon = unknownFile;
                        }

                        return (
                          <>
                            <div className="file-upload">
                              <Link
                                className="file-upload-link"
                                to={`/app-file-viewer`}
                                onClick={() => {
                                  localStorage.setItem("filePath", curr?.path);
                                  localStorage.setItem(
                                    "fileType",
                                    curr?.aws_name?.split(".").at(1)
                                  );
                                }}
                              >
                                {icon !== "" ? (
                                  <img
                                    src={icon}
                                    alt="File Icon"
                                    className="file-upload-image"
                                  />
                                ) : (
                                  <HelpCenterIcon />
                                )}
                                {curr?.aws_name
                                  ? curr?.aws_name
                                  : "unsupported format"}
                              </Link>
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <input
                        type="text"
                        id="files"
                        className="ticket-files"
                        value={"Not Found"}
                        style={{ backgroundColor: "#f1f2f6" }}
                        readOnly
                        required
                      />
                    )}
                  </div>
                  {ticketData?.length === 0 && (
                    <input
                      type="file"
                      id="file"
                      style={{ padding: "2px" }}
                      multiple
                      accept=".png,.jpg,.jpeg,.pdf, .docx, .doc, .xlsx, .xls, .csv, .mp4"
                      onChange={(e) => {
                        let totalSize = 0;
                        for (let i = 0; i < e.target.files.length; i++) {
                          totalSize = totalSize + e.target.files[i].size;
                        }
                        if (isStorageLimitReached(totalSize))
                          return setShowStorageLimitReachedPopup(true);
                        setLoader();
                        const mult_file = addingMultipleFilesST(
                          token,
                          ticketId,
                          e.target.files,
                          "false"
                        );
                        mult_file.then((res) => {
                          res.json().then((response) => {
                            if (response?.status) {
                              if (response?.message?.includes("limit"))
                                return toast(response?.message, {
                                  type: "info",
                                });
                              setFiles(response?.data);
                            } else {
                              toast(response?.message, { type: "info" });
                            }
                          });
                          resetLoader();
                        });
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="straisereq-fields">
                <label htmlFor="contact-email">
                  Location<span>*</span>:
                </label>
                {ticketData?.length === 0 ? (
                  <select
                    required
                    className="severity-wrapper"
                    onChange={(e) => {
                      setLocation(e.target.value);
                    }}
                    name="location"
                  >
                    <option key="" value="" selected disabled>
                      -- Select an option --
                    </option>
                    {location_list?.map((item) => (
                      <option
                        key={item?.key}
                        label={item?.location}
                        value={item?.id}
                      >
                        {item?.location}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input
                    style={{ backgroundColor: "#f1f2f6" }}
                    type="text"
                    value={ticketData?.locationName}
                    id="contact-email"
                    readOnly
                    required
                  />
                )}
              </div>
              <div className="stcreate-fields">
                <label htmlFor="severity">
                  Select Team<span>*</span>:
                </label>
                {ticketData?.length === 0 ? (
                  <select
                    required
                    id="team"
                    name="team"
                    className="severity-wrapper"
                    onChange={(e) => {
                      setCategory_id(e.target.value);
                    }}
                    disabled={location === ""}
                  >
                    <option key="" value="" selected disabled>
                      -- Select an option --
                    </option>
                    {teams?.length > 0 ? (
                      <>
                        {teams?.map((item) => (
                          <option key={item} value={item?.id}>
                            {item?.category_id}
                          </option>
                        ))}
                      </>
                    ) : (
                      <option disabled>No Team Found</option>
                    )}
                  </select>
                ) : (
                  <input
                    style={{ backgroundColor: "#f1f2f6" }}
                    type="text"
                    value={ticketData?.categoryName}
                    id="contact-email"
                    readOnly
                    required
                  />
                )}
              </div>

              <div className="stcreate-fields">
                <label htmlFor="severity">
                  Select User<span>*</span>:
                </label>
                <select
                  required
                  id="user"
                  name="user"
                  className="severity-wrapper"
                  disabled={
                    ticketData?.length === 0 &&
                    location === "" &&
                    category_id === ""
                  }
                  onChange={(e) => {
                    setAssign_tech(e.target.value);
                  }}
                >
                  <option key="" value="" selected disabled>
                    -- Select an option --
                  </option>
                  {assignedTech?.length > 0 ? (
                    <>
                      {assignedTech?.map((item) => (
                        <option key={item} value={item?.id}>
                          {item?.username}
                        </option>
                      ))}
                    </>
                  ) : (
                    <option disabled>No User Found</option>
                  )}
                </select>
              </div>
              <div className="stcreate-fields">
                <label htmlFor="description">
                  Brief Description<span>*</span>:
                </label>
                <textarea
                  id="description"
                  rows="5"
                  onChange={(e) => {
                    setDesc(e.target.value);
                  }}
                  name="description"
                  required
                ></textarea>
              </div>

              <div className="stcreate-fields">
                <button type="submit">Create New Ticket</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {showStorageLimitReachedPopup && (
        <StorageLimitReachedPopup hide={setShowStorageLimitReachedPopup} />
      )}
      {loader}
    </>
  );
};

export default CreateInternalTicket;
