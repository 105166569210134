import React, { useEffect, useState, useRef } from "react";
import "./AssetDetails.css";
import Headers from "Components/Header/Headers";
import Navigation from "Components/SideNavBar/Navigation";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { IconButton } from "@mui/material";
import bFolder from "Media/icons/Folder-Close.png";
import Low from "Media/icons/Low.png";
import Moderate from "Media/icons/Moderate.png";
import Pause from "Media/icons/Pause.png";
import Urgent from "Media/icons/Urgent.png";
import {
  getAssetByUniqueID,
  getAssetServiceTicket,
  getOrganizationConnection,
  getuploadedfiles,
  getAssetPart,
  createAssetFolder,
  getAssetFolders,
  getUserPermissions,
  getUserDetails,
  archiveAssetFolder,
  renameAssetFolder,
  getGeneralInfo,
  getLocationData,
} from "Config/API/get";
import { addAssetFile } from "Config/API/action";
import useIsStorageLimitReached from "Hooks/useIsStorageLimitReached";
import useFullPageLoader from "Hooks/useFullPageLoader"; // Loader component
import qrcode from "Media/icons/metro-qrcode.svg";
import shareIcon from "Media/icons/material-share.svg";
import { toast } from "react-toastify";
import uploadIcon from "Media/icons/awesome-file-upload.svg";
import folderSettings from "Media/icons/ionic-ios-settings.svg";
import { useDispatch, useSelector } from "react-redux";
import CreateIcon from "@mui/icons-material/Create";
import { Button } from "@mui/material";
import { updateAsset } from "Config/API/update";
import demo from "Media/blank-profile-picture.png";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import {
  assetuniqueid,
  ticketIdAction,
  assethashid,
} from "Redux/action/variable.action";
import AssetQrPopup from "Components/Popup/AssetQRPopup/assetQrPopup.jsx";
import options from "Media/icons/feather-more-vertical.svg";
import ShareAssetPopup from "Components/Popup/ShareAssetPopup/ShareAssetPopup";
import { renderOrganizationInfo } from "Redux/action/render.action";
import { getFileIcon } from "Constants/Functions";
import ServiceRequestForm from "pages/Assets/ServiceRequestForm/ServiceRequestForm";

const AssetDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [myAsset, setMyAsset] = useState(null);
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  const token = localStorage.getItem("token");
  const isStorageLimitReached = useIsStorageLimitReached();
  const pageType = useSelector((state) => state.servicePageTypeReducer);
  const [userData, setUserData] = useState("");
  const [generalInfo, setGeneralInfo] = useState("");
  useEffect(() => {
    getAssetDetails();
    getAssetServiceTickets();
    getAssetParts();
    getuserData();
  }, []);
  const getuserData = () => {
    const apiResponse = getUserDetails(token);
    apiResponse.then((res) => {
      setUserData(res.data.data.ticket_external_role);
    });
  };
  const [internalRequestPopup, setInternalRequestPopup] = useState(false);
  let doneTypingInterval = 300;
  const [qrPopup, setQrPopup] = useState(false);
  const [shareAssetPopup, setShareAssetPopup] = useState(false);
  const [vendorName, setVendorName] = useState("");
  const vendorRef = useRef(null);
  const locationRef = useRef(null);
  const [vendorNameId, setVendorNameid] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [organizations, setOrganizations] = useState([]);
  useOutsideAlerter(vendorRef);
  const [usersAllPermissions, setUsersAllPermissions] = useState([]);
  const [inputModal, setInputModal] = useState("");
  const [currentFolder, setCurrentFolder] = useState(null);
  const uniqueid = useSelector((state) => state.assetIdReducer);
  const [filterKeyword, setFilterKeyword] = useState("all");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [activeSection, setActiveSection] = useState("serviceRequests");
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const [assetFolders, setAssetFolders] = useState(null);
  const [assetServiceTicket, setAssetServiceTicket] = useState(null);
  const [assetPart, setAssetPart] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [editedAsset, setEditedAsset] = useState({});
  const [isVendorSelected, setIsVendorSelected] = useState(false);
  const [dropdownOpenId, setDropdownOpenId] = useState(null);
  const [showStorageLimitReachedPopup, setShowStorageLimitReachedPopup] =
    useState(false);
  const [assetLocation, setAssetLocation] = useState([]);
  const [uploadButtonDisable, setUploadButtonDisable] = useState(false);
  const [locationName, setLocationName] = useState();
  const [folderPath, setFolderPath] = useState([0]);
  const path = "";
  const [parentUniqueId, setParentUniqueId] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);
  const [typingTimer, setTypingTimer] = useState(null);

  const fullURL = window.location.href;
  const parsedURL = new URL(fullURL);
  const dropdownRef = useRef({});
  const [assetLocationType, setAssetLocationType] = useState("");
  const baseURL = parsedURL.protocol + "//" + parsedURL.hostname;
  const qrCodeUrl = myAsset?.path
    ?.toString()
    ?.replace(
      baseURL,
      `${baseURL}/amnius-connect/api/deep-link?url=amniusconnect://app`
    );

  const handleOpenModal = () => {
    setModalOpen(true);
    getbrowsefiles();
    getAssetFoldersData();
  };

  const handleDropdownClick = (id) => {
    setDropdownOpenId(dropdownOpenId === id ? null : id);
  };

  const handleOpen = (curr) => {
    if (curr.type === "folder") {
      setParentUniqueId(curr.unique_id);
      setFolderPath((prevItems) => [...prevItems, curr.unique_id]);
    } else {
      localStorage.setItem("filePath", curr?.path);
      localStorage.setItem(
        "fileType",
        curr?.name?.split(".").pop()?.toLowerCase()
      );
      window.open(
        `${window.location.origin}/file-explorer/file-viewer`,
        "_blank"
      );
    }
    setDropdownOpenId(null);
  };

  const handleRename = async (curr) => {
    setLoader();
    try {
      const response = await renameAssetFolder(
        token,
        currentFolder?.unique_id,
        folderName
      );
      if (response && response.status) {
        getAssetFoldersData();
        toast("Asset Renamed successfully", { type: "success" });
      } else {
        toast("Failed! Try again later", { type: "error" });
      }
    } catch (error) {
      toast("Failed! Try again later", { type: "error" });
    } finally {
      resetLoader();
    }
  };

  const handleDelete = async (curr) => {
    setLoader();
    try {
      const response = await archiveAssetFolder(token, curr?.unique_id);
      if (response && response.status) {
        getAssetFoldersData();
        toast("Asset Archived successfully", { type: "success" });
      } else {
        toast("Failed! Try again later", { type: "error" });
      }
    } catch (error) {
      toast("Failed! Try again later", { type: "error" });
    } finally {
      resetLoader();
      setDropdownOpenId(null);
    }
  };

  const handleOutsideClick = (event) => {
    const isOutside = Object.values(dropdownRef.current).every(
      (ref) => ref && !ref.contains(event.target)
    );
    if (isOutside) {
      setDropdownOpenId(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [dropdownOpenId]);

  useEffect(() => {
    const usersAllPermissions = getUserPermissions(token);
    usersAllPermissions.then((res) => {
      if (res?.status) {
        setUsersAllPermissions(res?.data?.emp);
      }
    });

    getloginorg();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getAssetFoldersData();
  }, [parentUniqueId]);

  const handleCloseModal = () => {
    setModalOpen(false);
    setParentUniqueId(0);
    setDropdownOpenId(null);
  };
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowDropdown(false); // Hide dropdown when clicking outside
        } else {
          setShowDropdown(true); // Show dropdown if clicking inside
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const addAFile = async (uploadFile) => {
    setUploadButtonDisable(true);
    const toastId = toast("Uploading, Please Wait...", { type: "info" });
    try {
      const res = await addAssetFile(
        token,
        myAsset.unique_id,
        path,
        parentUniqueId,
        uploadFile,
        { current: toastId }
      );

      if (res?.status) {
        if (res?.message?.includes("limit")) {
          return toast(res?.message, { type: "info" });
        }
        toast.update(toastId, {
          render: "Uploaded!",
          type: "success",
          autoClose: 4000,
        });
        getAssetFoldersData();
      } else {
        toast(res?.message, { type: "error" });
      }
    } catch (error) {
      toast("Failed! Try again later", { type: "error" });
    } finally {
      setUploadButtonDisable(false);
    }
  };
  const getAssetDetails = async () => {
    setLoader();
    try {
      const assetData = await getAssetByUniqueID(token, uniqueid);
      if (assetData && assetData.status) {
        // Check if status is true
        setMyAsset(assetData.data.data);
        setEditedAsset(assetData.data.data);
        setLocationName(assetData.data.data.assetLocation)
      } else {
        toast("Failed! Try again later", { type: "error" });
      }
    } catch (error) {
      toast("Failed! Try again later", { type: "error" });
    } finally {
      resetLoader();
    }
  };

  const createNewAssetFolder = async () => {
    setLoader();

    try {
      // Check if a folder with the same name already exists
      if (
        Array.isArray(assetFolders) &&
        assetFolders.some((folder) => folder.name === folderName)
      ) {
        toast("Folder name already exists", { type: "error" });
        resetLoader();
        return;
      }

      const assetData = await createAssetFolder(
        token,
        myAsset?.unique_id,
        folderName,
        parentUniqueId
      );

      if (assetData && assetData.status) {
        // If folder creation is successful
        toast("Asset Folder created successfully", { type: "success" });
        getAssetFoldersData();
      } else {
        toast("Failed! Try again later", { type: "error" });
      }
    } catch (error) {
      toast("Failed! Try again later", { type: "error" });
    } finally {
      resetLoader();
    }
  };

  const getbrowsefiles = async () => {
    setLoader();
    try {
      const uploadedfiles = await getuploadedfiles(token, uniqueid);

      if (uploadedfiles && uploadedfiles?.status) {
        setUploadedFiles(uploadedfiles?.data?.data);
      } else {
        toast("Failed! Try again later", { type: "error" });
      }
    } catch (error) {
      toast("Failed! Try again later", { type: "error" });
    } finally {
      resetLoader();
    }
  };
  const getAssetFoldersData = async () => {
    setLoader();
    try {
      const folders = await getAssetFolders(token, uniqueid, parentUniqueId);
      if (folders && folders?.status) {
        setAssetFolders(folders?.data?.data);
      } else {
        toast("Failed! Try again later", { type: "error" });
      }
    } catch (error) {
      toast("Failed! Try again later", { type: "error" });
    } finally {
      resetLoader();
    }
  };

  const getAssetServiceTickets = async () => {
    setLoader();
    try {
      const assetData = await getAssetServiceTicket(
        token,
        uniqueid,
        searchKeyword,
        filterKeyword
      );

      if (assetData && assetData.status) {
        setAssetServiceTicket(assetData.data.data);
      } else {
        toast("Failed! Try again later", { type: "error" });
      }
    } catch (error) {
      toast("Failed! Try again later", { type: "error" });
    } finally {
      resetLoader();
    }
  };
  const getAssetParts = async () => {
    setLoader();
    try {
      const assetData = await getAssetPart(token, uniqueid);

      if (assetData && assetData.status) {
        setAssetPart(assetData.data.data);
      } else {
        toast("Failed! Try again later", { type: "error" });
      }
    } catch (error) {
      toast("Failed! Try again later", { type: "error" });
    } finally {
      resetLoader();
    }
  };

  useEffect(() => {
    const handleTyping = () => {
      clearTimeout(typingTimer);
      const timer = setTimeout(() => {
        getAssetServiceTickets();
      }, doneTypingInterval);
      setTypingTimer(timer);
    };

    handleTyping();

    // Cleanup on unmount
    return () => {
      clearTimeout(typingTimer);
    };
  }, [searchKeyword, filterKeyword]);
  const handleEditClick = () => {
    setIsEditable(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedAsset((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const trimmedAssetName = editedAsset.assetsName?.trim();
    const trimmedAssetNumber = editedAsset.assetNumber?.toString().trim();
    const trimmedDescription = editedAsset.description?.trim();
    const trimmedLocation = editedAsset.assetLocation;
    const trimmedVendorName = isVendorSelected
      ? vendorNameId
      : editedAsset.vendorName.trim();
    if (
      trimmedAssetName !== "" &&
      trimmedVendorName !== "" &&
      trimmedDescription !== "" &&
      trimmedLocation !== ""
    ) {
      setLoader();
      const assetData = {
        token: token,
        unique_id: uniqueid,
        updates: {
          assetsName: trimmedAssetName,
          vendorName: trimmedVendorName,
          assetNumber: trimmedAssetNumber,
          description: trimmedDescription,
          assetLocation: trimmedLocation,
          assetLocationType: assetLocationType,
        },
      };
      try {
        const assetResponse = await updateAsset(assetData);
        resetLoader();

        if (assetResponse?.status) {
          toast("Asset Updated Successfully", { type: "success" });
          setIsEditable(false);
          getAssetDetails();
        } else {
          toast(assetResponse?.message, { type: "error" });
        }
      } catch (error) {
        resetLoader();
        toast("An error occurred, please try again", { type: "error" });
      }
    } else {
      if (trimmedAssetName === "")
        toast("Please enter an asset name", { type: "info" });
      if (trimmedVendorName === "")
        toast("Please select a vendor name", { type: "info" });
      if (trimmedDescription === "")
        toast("Please enter a description", { type: "info" });
      if (trimmedLocation === "")
        toast("Please enter a location", { type: "info" });
    }
  };
  const handleClose = () => {
    setIsEditable(false);
  };

  const handleGoBack = () => {
    if (folderPath[folderPath.length - 1] !== 0) {
      setParentUniqueId(folderPath[folderPath.length - 2]);
      setFolderPath((prevItems) => prevItems.slice(0, prevItems.length - 1));
    }
  };
  const fetchAndSetOrganizations = async (token, value) => {
    const vendorStatus = await getOrganizationConnection(token, value);

    if (vendorStatus?.status) {
      setOrganizations(vendorStatus.organizationData);
      setShowDropdown(true);
    } else {
      toast(vendorStatus?.message || "Error fetching organizations", {
        type: "error",
      });
    }
  };
  const getloginorg = async () => {
    const info = await getGeneralInfo(token);
    if (info?.status) {
      setGeneralInfo(info?.org_info);
    }
  };
  const getassetlocation = async () => {
    try {
      const info = await getGeneralInfo(token);
      const res = await getLocationData(token, info?.org_info?.id);

      if (res?.status === true) {
        // Check for the boolean value 'true'
        setAssetLocation(res.location);
        console.log(res.location); // Log the asset data directly
      } else {
        toast.error("No asset location found");
      }
    } catch (error) {
      toast.error(`Error fetching asset location: ${error.message}`);
      // Handle the error if needed (e.g., show a toast or alert)
    }
  };
  return (
    <>
      <div className="asset-details-parent">
        <div className="asset-details-header">
          <Headers />
        </div>
        <div className="asset-details-navigation">
          <Navigation />
        </div>
        <div className="asset-details-content">
          <div className="asset-info-section">
            {/* Asset Details */}
            <div className="my-asset-details">
              <div className="asset-details-left-header">
                <div className="edit-part">
                  {myAsset && myAsset.assetType === "created" ? (
                    <h2>My Asset</h2>
                  ) : (
                    <h2>Shared Asset</h2>
                  )}
                  {myAsset &&
                    myAsset.assetType === "created" &&
                    (!isEditable ? (
                      <button className="edit-button" onClick={handleEditClick}>
                        <CreateIcon /> &nbsp;Edit
                      </button>
                    ) : (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <button
                          className="header-one-btn-one"
                          onClick={handleSubmit}
                          style={{ marginLeft: "10px", padding: "8px" }}
                        >
                          Submit
                        </button>
                        <IconButton onClick={() => handleClose()}>
                          <CloseIcon />
                        </IconButton>
                      </div>
                    ))}
                </div>

                <button
                  className="service-request"
                  onClick={() => {
                    dispatch(assetuniqueid(uniqueid));
                    dispatch(assethashid(editedAsset.assetNumber));
                    setInternalRequestPopup(true);
                  }}
                >
                  SERVICE REQUEST
                </button>
              </div>
              {myAsset ? (
                <div className="asset-info">
                  <div className="asset-detail-field">
                    <strong>Asset Owner:</strong>
                    <div className="asset-owner">
                      <input
                        type="text"
                        name="organization_name"
                        value={myAsset.organizationDetail.organization_name}
                        readOnly
                        onChange={handleInputChange}
                        style={{ marginRight: "12px", width: "100%" }}
                      />

                      <div className="visit-icon">
                        <img
                          src={
                            myAsset.organizationDetail.logo
                              ? myAsset.organizationDetail.logo
                              : demo
                          }
                          alt="Logo"
                        />
                        <Button
                          variant="text"
                          className="visit-page-cta"
                          onClick={() => {
                            navigate("/organization", {
                              state: {
                                data:
                                  myAsset && myAsset.assetType === "shared"
                                    ? myAsset?.createdBy
                                    : myAsset.org_id,
                                ...(myAsset &&
                                  myAsset.assetType !== "shared" && {
                                    from: "navigation",
                                  }),
                              },
                            });
                            dispatch(renderOrganizationInfo({}));
                          }}
                        >
                          VISIT PAGE
                        </Button>
                      </div>
                    </div>
                  </div>

                  <div className="asset-detail-field">
                    <strong>
                      Asset Name<span className="red"> *</span>:
                    </strong>
                    <input
                      type="text"
                      name="assetsName"
                      value={editedAsset.assetsName}
                      readOnly={!isEditable}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="asset-detail-field">
                    <strong>
                      Asset #<span className="red">*</span>:
                    </strong>
                    <input
                      type="text"
                      name="assetNumber"
                      value={editedAsset.assetNumber}
                      readOnly={!isEditable}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="asset-detail-field">
                    <strong>Description:</strong>
                    <textarea
                      name="description"
                      value={editedAsset.description}
                      readOnly={!isEditable}
                      onChange={handleInputChange}
                      rows="4"
                      style={{ resize: "none" }}
                    />
                  </div>

                  <div className="asset-detail-field">
                    <strong>Asset Location:</strong>
                    <div ref={locationRef} className="vendorname">
                      <input
                        type="text"
                        name="assetLocation"
                        value={
                         locationName
                        }
                        placeholder="Location"
                        readOnly={!isEditable}
                        onChange={(e) => {
                          setEditedAsset((prev) => ({
                            ...prev,
                            assetLocation: e.target.value,
                          }));
                          setAssetLocationType("text");
                          setLocationName(e.target.value)
                          setShowLocation(false);
                        }}
                        onClick={() => {
                          getassetlocation(); // Assuming you have a function getAssetLocation defined elsewhere
                          setShowLocation(true);

                        }}
                      />

                      {showLocation && isEditable && (
                        <div
                          className="organization-list asset-list"
                          style={{ zIndex: "10" }}
                        >
                          {assetLocation &&
                            assetLocation.length > 0 &&
                            assetLocation.map((loc) => (
                              <div
                                className="data-list"
                                key={loc.id}
                                onClick={() => {
                                  setEditedAsset((prev) => ({
                                    ...prev,
                                    assetLocation: loc.id,
                                  }));
                                  setAssetLocationType("id");
                                  setLocationName(loc.location )
                                  setShowLocation(false);
                                }}
                              >
                                <span className="multiple-line-text">
                                  {loc.location || "No Location"}
                                </span>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="asset-detail-field">
                    <strong>Vendor Name:</strong>
                    <div ref={vendorRef} className="vendorname">
                      <input
                        type="text"
                        name="vendorName"
                        value={editedAsset.vendorName}
                        placeholder="Vendor Name"
                        readOnly={!isEditable}
                        onChange={(e) => {
                          handleInputChange(e); // Update the vendor name in editedAsset
                          setIsVendorSelected(false); // Reset selection state when typing
                          fetchAndSetOrganizations(token, e.target.value);
                        }}
                        onClick={() => {
                          if (!editedAsset.vendorName.length) {
                            fetchAndSetOrganizations(token, "");
                          }
                        }}
                      />

                      {showDropdown && isEditable && (
                        <div className="organization-list asset-list">
                          {!organizations.some(
                            (org) => org.id === generalInfo?.id
                          ) &&
                            generalInfo && (
                              <div
                                className="data-list"
                                key="generalInfo"
                                onClick={() => {
                                  setVendorName(generalInfo?.organization_name);
                                  setVendorNameid(generalInfo.id);
                                  setOrganizations([]);
                                  setShowDropdown(false);
                                  setIsVendorSelected(true); // Set selection state to true
                                  setEditedAsset((prev) => ({
                                    ...prev,
                                    vendorName: generalInfo?.organization_name,
                                  }));
                                }}
                              >
                                <div>
                                  <div className="organization-img-parent">
                                    <img src={generalInfo?.logo} alt="Vendor" />
                                  </div>

                                  <span
                                    className="multiple-line-text"
                                    style={{ color: "#51595E" }}
                                  >
                                    {generalInfo?.organization_name ||
                                      "No Name"}
                                  </span>
                                </div>
                              </div>
                            )}
                          {organizations.length > 0 &&
                            organizations.map((vendor, index) => (
                              <div
                                className="data-list"
                                key={index}
                                onClick={() => {
                                  setVendorName(vendor.organization_name);
                                  setVendorNameid(vendor.id);
                                  setOrganizations([]);
                                  setShowDropdown(false);
                                  setIsVendorSelected(true); // Set selection state to true
                                  setEditedAsset((prev) => ({
                                    ...prev,
                                    vendorName: vendor.organization_name,
                                  }));
                                }}
                              >
                                <div className="organization-img-parent">
                                  <img src={vendor.logo} alt="Vendor" />
                                </div>
                                <span className="multiple-line-text">
                                  {vendor.organization_name || "No Name"}
                                </span>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="asset-detail-field">No assets available.</div>
              )}

              {/* Buttons */}
              <div className="asset-buttons">
                <button
                  className="asset-button"
                  style={{ borderRadius: "0 0 0 10px" }}
                  onClick={() => setQrPopup(true)}
                >
                  <img src={qrcode} width="21px" alt="Add Folder" />
                  <p>Create QR Code</p>
                </button>
                {qrPopup ? (
                  <>
                    <AssetQrPopup
                      orglogo={myAsset.organizationDetail.logo}
                      assetName={myAsset.assetsName}
                      // internalFolder={internalFolder}
                      hide={setQrPopup}
                      path={qrCodeUrl}
                    />
                  </>
                ) : null}
                {myAsset && myAsset.assetType === "created" && (
                  <button
                    className="asset-button"
                    onClick={() => setShareAssetPopup(true)}
                  >
                    <img src={shareIcon} width="21px" alt="Add Folder" />
                    <p>Share Asset</p>
                  </button>
                )}
                {shareAssetPopup ? (
                  <>
                    <ShareAssetPopup
                      hide={setShareAssetPopup}
                      assetId={uniqueid}
                    />
                  </>
                ) : null}
                <button className="asset-button" onClick={handleOpenModal}>
                  <img src={uploadIcon} width="21px" alt="Add Folder" />
                  <p>Browse Files</p>
                </button>

                <Modal
                  open={inputModal?.length > 0}
                  onClose={() => setInputModal("")}
                >
                  <div className="modal-content-input">
                    <input
                      type="text"
                      value={folderName}
                      style={{
                        fontSize: "14px",
                        marginRight: "12px",
                        width: "100%",
                      }}
                      placeholder="Enter folder name"
                      onChange={(e) => {
                        setFolderName(e.target.value);
                      }}
                    />
                    <button
                      onClick={() => {
                        setInputModal("");
                        setFolderName("");
                        if (!folderName?.trim()?.length) return;
                        if (inputModal === "folder") {
                          createNewAssetFolder();
                        } else if (inputModal === "rename") {
                          handleRename();
                        }
                      }}
                      style={{ alignSelf: "center" }}
                      className="service-request"
                    >
                      Submit
                    </button>
                  </div>
                </Modal>

                <Modal open={isModalOpen} onClose={handleCloseModal}>
                  <div className="modal-content">
                    <div className="folders-close">
                      <IconButton onClick={() => handleCloseModal()}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                    <div className="modal-header">
                      <h2>Uploaded Files</h2>
                      {myAsset && myAsset.assetType === "created" && (
                        <div className="filesfolders">
                          <button
                            onClick={() => setInputModal("folder")}
                            className="create-folder"
                          >
                            Create Folder
                          </button>

                          <label
                            htmlFor="uploadFile"
                            className="upload-file-btn"
                          >
                            <img
                              src={uploadIcon}
                              width="15px"
                              alt="Add Folder"
                            />
                            <p>Upload file</p>
                          </label>
                        </div>
                      )}
                    </div>
                    <div className="top-button-container">
                      {folderPath[folderPath.length - 1] === 0 ? (
                        <div />
                      ) : (
                        <button
                          onClick={() => handleGoBack()}
                          className="create-folder go-back-btn"
                        >
                          Go Back
                        </button>
                      )}

                      <input
                        type="file"
                        id="uploadFile"
                        hidden
                        disabled={uploadButtonDisable}
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (!file) return;
                          const size = file.size;
                          if (isStorageLimitReached(size)) {
                            setShowStorageLimitReachedPopup(true);
                            return;
                          }
                          addAFile(file);
                          e.target.value = "";
                        }}
                        style={{
                          pointerEvents: uploadButtonDisable ? "none" : "auto",
                        }}
                      />
                    </div>
                    <div className="modal-body">
                      {Array.isArray(assetFolders) &&
                      assetFolders.length > 0 ? (
                        <div className="asset_single_folder">
                          {assetFolders?.map((curr, index) => (
                            <div
                              onClick={() => setDropdownOpenId(curr.unique_id)}
                              className="assets-container-grid"
                              key={index}
                            >
                              <div className="assets-container">
                                <div
                                  className="dropdown-icon-container"
                                  ref={(el) =>
                                    (dropdownRef.current[curr.unique_id] = el)
                                  }
                                >
                                  <img
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleDropdownClick(curr.unique_id);
                                    }}
                                    src={options}
                                    alt="Menu options"
                                    className="dropdown-icon"
                                  />

                                  {/* Dropdown Menu */}
                                  {dropdownOpenId === curr.unique_id && (
                                    <div
                                      onClick={(e) => e.stopPropagation()}
                                      className="dropdown-menu"
                                    >
                                      <button onClick={() => handleOpen(curr)}>
                                        Open
                                      </button>
                                      <button
                                        onClick={() => {
                                          setFolderName(
                                            curr?.name?.includes(".")
                                              ? curr?.name
                                                  .split(".")
                                                  .slice(0, -1)
                                                  .join(".")
                                              : curr?.name
                                          );
                                          setCurrentFolder(curr);
                                          setInputModal("rename");
                                          setDropdownOpenId(null);
                                        }}
                                      >
                                        Rename
                                      </button>
                                      <button
                                        onClick={() => handleDelete(curr)}
                                      >
                                        Archive
                                      </button>
                                    </div>
                                  )}
                                </div>

                                <div className="asset-item">
                                  <div className="asset-icon">
                                    {curr.type === "folder" ? (
                                      <img src={bFolder} alt="Folder Icon" />
                                    ) : (
                                      <img
                                        src={getFileIcon(curr)}
                                        alt="File Icon"
                                      />
                                    )}
                                  </div>
                                  <div className="asset-name">
                                    {curr?.name ? curr?.name : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <p style={{ padding: "0 30px" }}>No files uploaded.</p>
                      )}
                    </div>
                  </div>
                </Modal>
                <button
                  className="asset-button"
                  style={{ borderRadius: "0 0 10px 0", border: "none" }}
                  onClick={() => {
                    navigate("/service-ticket-user-management");
                  }}
                >
                  <img src={folderSettings} width="21px" alt="Add Folder" />
                  <p>User Management</p>
                </button>
              </div>
            </div>

            {/* Active Service Tickets Section */}
            <div className="service-tickets-section">
              <div className="service-tickets-header">
                <div className="header-one">
                  {/* Button to show service requests section */}
                  <button
                    className={` ${
                      activeSection === "serviceRequests"
                        ? "active header-one-btn-one"
                        : "header-one-btn-two"
                    }`}
                    onClick={() => setActiveSection("serviceRequests")}
                  >
                    SERVICE REQUESTS
                  </button>

                  {/* Button to show parts management section */}
                  <button
                    className={` ${
                      activeSection === "partsManagement"
                        ? "active header-one-btn-one"
                        : "header-one-btn-two"
                    }`}
                    onClick={() => setActiveSection("partsManagement")}
                  >
                    PARTS MANAGEMENT
                  </button>
                </div>

                {/* Conditionally render the header based on active section */}
                {activeSection === "serviceRequests" ? (
                  <div className="header-two">
                    <h2>Active Service Tickets</h2>
                    {myAsset && myAsset.assetType === "created" && userData && (
                      <button
                        className="header-one-btn-one"
                        onClick={() => {
                          dispatch(assetuniqueid(uniqueid));
                          dispatch(assethashid(editedAsset.assetNumber));
                          navigate("/asset-service-ticket-create", {
                            state: {
                              ticketType: "new ticket",
                            },
                          });
                        }}
                      >
                        CREATE NEW TICKET
                      </button>
                    )}
                  </div>
                ) : (
                  <div className="header-two">
                    <h2>Parts for Asset</h2>
                    {myAsset && myAsset.assetType === "created" && userData && (
                      <button
                        className="header-one-btn-one "
                        style={{ padding: "8px 40px" }}
                        onClick={() => {
                          dispatch(assetuniqueid(uniqueid));
                          navigate("/add-asset-part");
                        }}
                      >
                        ADD PART
                      </button>
                    )}
                  </div>
                )}
              </div>

              {/* Conditionally render the content based on active section */}
              {activeSection === "serviceRequests" ? (
                <div>
                  <div className="service-search-filter">
                    <div className="asset-search">
                      <input
                        type="text"
                        placeholder="Search tickets by ticket # or requestor name"
                        onChange={(e) => {
                          setSearchKeyword(e.target.value);
                          setSearchKeyword(e.target.value);
                        }}
                      />
                      <SearchIcon className="search-icon" />
                    </div>
                    <div className="asset-filter">
                      <label>Filter:</label>
                      <select
                        value={filterKeyword}
                        onChange={(e) => {
                          setFilterKeyword(e.target.value); // Update filter state
                        }}
                      >
                        <option value="all">All</option>
                        <option value="urgent">Urgent</option>
                        <option value="moderate">Moderate</option>
                        <option value="low">Low</option>
                      </select>
                    </div>
                  </div>
                  <div className="service-ticket-table">
                    <div className="service-ticket-label">
                      <div className="asset-head-row">TICKET#</div>
                      <div className="asset-head-row">REQUESTOR</div>
                      <div className="asset-head-row">ASSIGNED TO</div>
                      <div className="asset-head-row">SEVERITY</div>
                      <div className="asset-head-row">OPEN DATE</div>
                    </div>
                    {assetServiceTicket?.length > 0 ? (
                      <div>
                        {assetServiceTicket.map((ticket, index) => (
                          <div key={index} className="service-ticket-content">
                            <div
                              className="asset-row"
                              onClick={() => {
                                dispatch(ticketIdAction(ticket.ticket_id));
                                navigate("/service-ticket-details");
                              }}
                              style={{ color: "#436EA0", fontWeight: 700 }}
                            >
                              {ticket.ticket_id}
                            </div>
                            <div className="asset-row">
                              {ticket.requestor || "N/A"}
                            </div>
                            <div className="asset-row">
                              {ticket.assign_tech || "N/A"}
                            </div>
                            <div className="asset-row severity">
                              {ticket.severity_issue.toString() === "urgent" ? (
                                <>
                                  <img src={Urgent} alt="Urgent" /> Urgent
                                </>
                              ) : ticket.severity_issue.toString() ===
                                "moderate" ? (
                                <>
                                  <img src={Moderate} alt="Moderate" /> Moderate
                                </>
                              ) : ticket.severity_issue.toString() === "low" ? (
                                <>
                                  <img src={Low} alt="Low" /> Low
                                </>
                              ) : ticket.severity_issue.toString() ===
                                "pause" ? (
                                <>
                                  <img src={Pause} alt="Pause" /> Paused
                                </>
                              ) : ticket.severity_issue.toString() ===
                                "closed" ? (
                                <>
                                  <span
                                    style={{
                                      backgroundColor: "orange",
                                      borderRadius: "2px",
                                      textAlign: "center",
                                      width: "20px",
                                      height: "18px",
                                      color: "white",
                                      marginRight: "5px",
                                    }}
                                  >
                                    C
                                  </span>
                                  Closed
                                </>
                              ) : null}
                            </div>
                            <div className="asset-row">
                              {new Date(ticket.createdAt).toLocaleDateString()}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="service-ticket-content">
                        <p className="no-ticket">
                          No Ticket Exist for asset {editedAsset?.assetNumber}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  <div className="service-search-filter">
                    <div className="asset-search">
                      <input type="text" placeholder="Search part by parts# " />
                      <SearchIcon className="search-icon" />
                    </div>
                  </div>
                  <div className="service-ticket-table">
                    <div className="service-ticket-label">
                      <div className=" parts">PART#</div>
                      <div className=" parts-description">DESCRIPTION</div>
                      <div className=" parts">QUANTITY ON HAND</div>
                    </div>
                    {assetPart.length > 0 ? (
                      <div>
                        {assetPart.map((ticket, index) => (
                          <div key={index} className="service-ticket-content">
                            <div
                              className=" part-content"
                              onClick={() => {
                                dispatch(ticketIdAction(ticket.unique_id));
                                navigate("/part-detail");
                              }}
                              style={{ color: "#436EA0", fontWeight: 700 }}
                            >
                              {ticket.unique_id}
                            </div>
                            <div className=" part-description">
                              {ticket.description || "N/A"}
                            </div>

                            <div className="part-content">
                              {new Date(ticket.createdAt).toLocaleDateString()}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="service-ticket-content">
                        <p className="no-ticket">
                          No Part Exist for asset {editedAsset.assetNumber}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {internalRequestPopup ? (
        <ServiceRequestForm
          hide={() => setInternalRequestPopup(false)} // Function to hide the form
          service_org_id={myAsset.organizationDetail.id}
          assetRequesttype={
            myAsset.assetType === "created" ? "internal" : "external"
          }
        />
      ) : null}
      {loader}
    </>
  );
};

export default AssetDetails;
